import { useEventDataProvider } from '../use-event-data';
import { useEvents } from '../use-events';
import { useFlag } from '../use-flag';
import { fetchEventSource } from './fetch';
import { ProgramEvent } from '@cue/api';
import { LocalStorageUtil, TimeUtil } from '@cue/utility';
import { LocationCategory } from '@project/cms/modules';
import { LivestreamSourceProps } from '@project/cms/modules/livestream';
import LivestreamConfigJSON from '@project/local/livestream.json';
import { useEffect, useMemo, useState } from 'react';
import { singletonHook } from 'react-singleton-hook';
import { v4 as uuidv4 } from 'uuid';

interface ServerResponseData {
  channel: string;
  event: string | null;
  started?: string;
  status: LivestreamStatusType;
}
const livestreamData = LivestreamConfigJSON as LivestreamSourceProps[];

export interface LivestreamStatus extends ServerResponseData {
  offset?: number;
  eventData: ProgramEvent | undefined;
}
export enum LivestreamStatusType {
  DEFAULT = 'default',
  ACTIVE = 'active',
  IDLE = 'idle',
  ISSUE = 'issue',
  BEFORE_EVENT = 'before-event',
  AFTER_EVENT = 'after-event',
  DISABLED = 'disabled',
  NO_SESSION = 'no-session',
}
export const _useLivesstreamStatusImpl = () => {
  const base_url = process.env.STREAM_CONTROL_URL;
  const [connected, setConnected] = useState(false);
  const [inter, setInter] = useState<string>();
  const [status, setStatus] = useState<LivestreamStatus[]>([]);
  const [serverStatus, setServerStatus] = useState<ServerResponseData[]>([]);
  const [internalStatus, setInternalStatus] = useState<ServerResponseData[]>(
    livestreamData.map((streams) => ({
      channel: streams.categories?.[0]?.slug || 'default',
      event: null,
      status: LivestreamStatusType.DEFAULT,
    }))
  );
  const { todayRange, currentEvent, firstEventToday } = useEventDataProvider();
  const now = TimeUtil.getNowUtc();

  const getLivestreamStatus = (channel: string, offsets: GetStatusProps): LivestreamStatusType => {
    const { offset_start = 0, offset_end = 0 } = offsets;
    const range = todayRange({
      slugs: ['agenda'],
      category: channel !== 'default' ? [channel] : undefined,
    });
    if (now.isBefore(range?.[0].subtract(offset_start * 1000))) {
      return LivestreamStatusType.BEFORE_EVENT;
    } else if (now.isAfter(range?.[1].add(offset_end * 1000))) {
      return LivestreamStatusType.AFTER_EVENT;
    }

    return LivestreamStatusType.ACTIVE;
  };

  /*   useEffect(() => {
    setInternalStatus((last) =>
      last.map((channel) => ({ ...channel, status: getLivestreamStatus(channel.channel) }))
    );
  }, [inter]); */

  /*  useEffect(() => {
    console.log('firstEventToday', firstEventToday({ slugs: 'agenda' }));
  }, [inter]); */

  const { events } = useEvents();
  const { mockDiffMs } = LocalStorageUtil.get('mockTime') || { mockDiffMs: 0 };
  const calculateOffset = () => {
    return '';
  };

  const fetchInitData = async () => {
    const data = (await (
      await fetch(`${base_url}/livestream-status-static`)
    ).json()) as ServerResponseData[];
    return data;
  };
  useEffect(() => {
    const initSEE = async () => {
      await fetchEventSource(`${base_url}/livestream-status`, {
        headers: {
          'Content-Type': 'application/json',
        },
        maxRetry: 10,
        async onopen(res) {
          if (res.ok && res.status === 200) {
            console.log('Connection made ', res);
            const initData = await fetchInitData();
            setServerStatus(initData);
            setConnected(true);
          } else if (res.status >= 400 && res.status < 500 && res.status !== 429) {
            console.log('Client side error ', res);
          }
        },
        onmessage(event) {
          setServerStatus(JSON.parse(JSON.parse(event.data)));
          setInter(uuidv4());
        },
        onclose() {
          console.warn('Connection closed by the server');
          setConnected(false);
        },
        onerror(err) {
          console.warn('There was an error from server', err);
          setConnected(false);
        },
      });
    };
    if (base_url) initSEE();
  }, []);

  useEffect(() => {
    if (events) {
      setStatus(
        serverStatus?.map((stream) => {
          //
          const event = events?.find((event) => event.id === stream.event);
          const ret = {
            ...stream,
            offset: event
              ? (stream?.started && event?.start
                  ? TimeUtil.get(stream?.started).diff(TimeUtil.get(event?.start))
                  : 0) +
                mockDiffMs * -1
              : 0,
          };
          const eventData = currentEvent({
            slugs: 'agenda',
            categories: [stream.channel],
            offset: ret.offset,
          });

          return { ...ret, eventData: eventData?.[0] };
        })
      );
    }
  }, [serverStatus, events]);

  const getInternalStatus = (props?: GetStatusProps) => {
    if (!props) props = { offset_start: 0, offset_end: 0 };
    return internalStatus.map((channel) => ({
      ...channel,
      status: getLivestreamStatus(channel.channel, props),
    }));
  };

  const isLive =
    useMemo(() => {
      const serverStatus = status?.some((channel) =>
        [LivestreamStatusType.ACTIVE, LivestreamStatusType.IDLE].includes(channel?.status)
      );
      if (serverStatus) return true;

      return getInternalStatus()?.some((channel) =>
        [LivestreamStatusType.ACTIVE, LivestreamStatusType.IDLE].includes(channel?.status)
      );
    }, [status, internalStatus]) || false;

  const statusByCategories = (
    categories: LocationCategory['category']['slug'][] = [],
    props: GetStatusProps
  ) => {
    const hasIssue = useFlag('livestream_issue');

    const ret = (connected ? status : getInternalStatus(props)).find((channel) => {
      return !categories?.length ? true : categories?.includes(channel.channel);
    });
    if (connected && ret?.status === LivestreamStatusType.DEFAULT) {
      return getInternalStatus(props).find((channel) => {
        return !categories?.length ? true : categories?.includes(channel.channel);
      });
    }

    if (
      ret?.status === LivestreamStatusType.AFTER_EVENT &&
      props?.no_session_video_timed &&
      props?.no_session_video_timed_start &&
      props?.no_session_video_timed_end
    ) {
      if (
        now.isBetween(
          TimeUtil.getUtc(props.no_session_video_timed_start, 'HH:mm'),
          TimeUtil.getUtc(props.no_session_video_timed_end, 'HH:mm')
        )
      ) {
        ret.status = LivestreamStatusType.NO_SESSION;
      }

      /*      now.isBetween(
        TimeUtil.getUtc(no_session_video_timed_start, 'HH:mm'),
        TimeUtil.getUtc(no_session_video_timed_end, 'HH:mm')
      ) || false */
    }
    if (hasIssue) ret.status = LivestreamStatusType.ISSUE;
    return ret;
  };

  return { inter, connected, isLive, status, statusByCategories };
};

const defaultState = {
  inter: '',
  connected: false,
  status: [],
  isLive: false,
  statusByCategories: () => {
    return;
  },
  getStatus: () => {
    return;
  },
};

export const useLivestreamStatus = singletonHook<useLivestreamStatusReturn>(
  defaultState,
  _useLivesstreamStatusImpl,
  {
    unmountIfNoConsumers: true,
  }
);

export interface useLivestreamStatusReturn {
  inter: string;
  isLive: boolean;
  status: LivestreamStatus[];
  statusByCategories(
    categories?: LocationCategory['category']['slug'][],
    props?: GetStatusProps
  ): LivestreamStatus;
}
interface GetStatusProps {
  offset_start?: number;
  offset_end?: number;
  no_session_video_timed?: boolean | null;
  no_session_video_timed_start?: string | null;
  no_session_video_timed_end?: string | null;
}
