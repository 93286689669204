import type { SVGProps } from 'react';

const SvgEmail = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 4V20H22V4H2ZM18.37 6L12 11.66L5.63 6H18.37ZM4 7.23V18H20V7.23L12 14.34L4 7.23Z"
    />
  </svg>
);
export default SvgEmail;
