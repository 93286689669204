import type { SVGProps } from 'react';

const SvgVolumeLoud = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M18.9417 4.04651L17.3815 5.29468C19.0592 7.12474 19.9898 9.51724 19.9899 11.9999C19.9899 14.4826 19.0592 16.8751 17.3815 18.7052L18.9418 19.9534C20.9023 17.7682 21.9867 14.9358 21.9866 12C21.9866 9.06413 20.9023 6.23175 18.9417 4.04651Z" />
    <path d="M14.9862 7.211L13.4241 8.46063C14.3229 9.41996 14.8231 10.6853 14.8231 12C14.8231 13.3146 14.323 14.58 13.4241 15.5393L14.9862 16.7889C16.1679 15.4737 16.8215 13.768 16.8215 12C16.8215 10.2319 16.1678 8.52622 14.9862 7.211Z" />
    <path d="M10.9941 20.0352L5.80371 16.0293H2.00586V8.0293H5.79786L10.9941 3.94043L10.9941 20.0352ZM4.00586 14.0293H6.48535L8.99414 15.9648V8.05957L6.49121 10.0293H4.00586V14.0293Z" />
  </svg>
);
export default SvgVolumeLoud;
