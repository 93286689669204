import type { SVGProps } from 'react';

const SvgClock = (props: SVGProps<SVGSVGElement>) => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth={0}
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    className="icon icon-right"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M12,2.1c-5.5,0-9.9,4.4-9.9,9.9s4.4,9.9,9.9,9.9s9.9-4.4,9.9-9.9S17.5,2.1,12,2.1z M12,19.9c-4.4,0-7.9-3.6-7.9-7.9S7.6,4.1,12,4.1s7.9,3.6,7.9,7.9S16.4,19.9,12,19.9z M13,7h-2v6.4l3.3,3.3l1.4-1.4L13,12.6V7z" />
  </svg>
);
export default SvgClock;
