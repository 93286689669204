import type { SVGProps } from 'react';

const SvgHome = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 10L12 2L22 10V22H14V16H10V22H2V10ZM16 20H20V11L12 4.6L4 11V20H8V14H16V20Z"
    />
  </svg>
);
export default SvgHome;
