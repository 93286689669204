/* eslint-disable */
// TODO eslint typecheck
// @ts-nocheck
import { COOKIE_CONSENT_TYPE, ConsentTypes } from '../cookie-consent';
import { CallToActionButtonProps, ModalCtaConfig, TranslationCta } from './types';
import { Button, Heading } from '@cue/atoms';
import { useCrispChat, useQueryParams } from '@cue/hooks';
import { Modal, useModal } from '@cue/organisms';
import { LocalStorageUtil, Mdx } from '@cue/utility';
import { AnalyticsTransformer } from '@cue/utility';
// import { AdhocTalkRequest } from '@cue/site/adhoc';
// import { PageParams } from '@cue/site/pageFrame';
import styled from '@emotion/styled';
import { useFlag } from '@project/hooks/use-flag';
import { useUser } from '@project/hooks/use-user';
// import { graphql } from 'gatsby';
import { navigate } from 'gatsby';
import React, { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// TODO: This is not a provider -> Find a good place
// Refactor & find a better implementation to detect if a CallToActionButton will render somehting (than to hide everything, but the first via css)

function CallToActionButton(props: CallToActionButtonProps) {
  const { flag, style, icon, translations, onClick, className } = props;
  const isActive = useFlag(flag?.slug);
  const { i18n } = useTranslation();
  const button = React.useMemo(() => {
    switch (style) {
      case 'hero':
        return (
          <Button
            styling="primary"
            icon={icon?.component}
            onClick={() => onClick && onClick()}
            className={className}>
            {
              translations?.find(
                (t: TranslationCta) => t?.languages_code?.code.split('-')?.[0] === i18n.language
              )?.call_to_action
            }
          </Button>
        );
        break;
      case 'secondary':
        return (
          <Button
            styling="secondary"
            icon={icon?.component}
            onClick={() => onClick && onClick()}
            className={className}>
            {
              translations?.find(
                (t: TranslationCta) => t?.languages_code?.code.split('-')?.[0] === i18n.language
              )?.call_to_action
            }
          </Button>
        );
        break;

      case 'small':
        return (
          <Button
            styling="small"
            icon={icon?.component}
            onClick={() => onClick && onClick()}
            className={className}>
            {
              translations?.find(
                (t: TranslationCta) => t?.languages_code?.code.split('-')?.[0] === i18n.language
              )?.call_to_action
            }
          </Button>
        );
        break;

      case 'round':
        return (
          <Button
            styling="round"
            icon={icon?.component}
            onClick={() => onClick && onClick()}
            className={className}>
            {
              translations?.find(
                (t: TranslationCta) => t?.languages_code?.code.split('-')?.[0] === i18n.language
              )?.call_to_action
            }
          </Button>
        );
        break;

      case 'side_badge':
        return (
          <Button
            styling="side_badge"
            icon={icon?.component}
            onClick={() => onClick && onClick()}
            className="side_badge "
            className={className}>
            {
              translations?.find(
                (t: TranslationCta) => t?.languages_code?.code.split('-')?.[0] === i18n.language
              )?.call_to_action
            }
          </Button>
        );
        break;

      default:
        return (
          <Button
            styling="default"
            icon={icon?.component}
            onClick={() => onClick && onClick()}
            className={className}>
            {
              translations?.find(
                (t: TranslationCta) => t?.languages_code?.code.split('-')?.[0] === i18n.language
              )?.call_to_action
            }
          </Button>
        );
    }
  }, [props]);

  if (isActive === false || (flag && isActive === null)) return null;

  return button || null;
}

export const CallToActionOverlay: React.FC<
  PageParams<Queries.getPageContentByPageIdQuery> & { children: ReactNode }
> = (props: PageParams<Queries.getPageContentByPageIdQuery> & { children: ReactNode }) => {
  const { pageContext } = props;
  const callToActions = pageContext?.pageInfo?.callToActions || [];
  const { user, isAuthenticated } = useUser();
  const { i18n, t } = useTranslation();

  const { chat, load, setUser } = useCrispChat({
    needCookieConsent: true,
    CRISP_WEBSITE_ID: process.env.CRISP_WEBSITE_ID,
    options: { autoload: false },
  });


  useEffect(() => {
    chat.onChatClosed(() => {
      chat.hide();
      document.body.setAttribute('data-crisp-open', 'false');

      AnalyticsTransformer.customEvent(['support', `chat:close`]);
    });

    chat.onChatOpened(() => {
      document.body.setAttribute('data-crisp-open', 'true');
    });
  }, []);

  useEffect(() => {
    if (user) {
      setUser(`${user.firstName} ${user.lastName} (${user.userId})`);
    } else {
      setUser('');
    }
  }, [user]);

  const [chatBlockedByCookieSettingsModalRef] = useModal();
  const [adHocModalRef] = useModal();

  const clickActions: Record<string, (ctaConfig: ModalCtaConfig) => void> = {
    'adhoc-modal': (ctaConfig: ModalCtaConfig) => {
      isAuthenticated && adHocModalRef.current?.open();
    },
    'open-link': (ctaConfig: ModalCtaConfig) => {
      const link =
        ctaConfig?.translations?.find(
          (t: TranslationCta) => t?.languages_code?.code.split('-')?.[0] === i18n.language
        )?.link ||
        ctaConfig?.translations?.find(
          (t: TranslationCta) => t?.languages_code?.code.split('-')?.[0] === 'en'
        )?.link;

      if (!link) return;

      if (String(link).startsWith('http')) {
        if (ctaConfig.open_in_new_tab) {
          // window && window.open(link, '_blank')?.focus();
          return;
        }
        location.href = link;
      }

      navigate(link);
    },
    crisp_chat: (ctaConfig: ModalCtaConfig) => {
      const cookieConsent = LocalStorageUtil.get(COOKIE_CONSENT_TYPE);
      if (cookieConsent === ConsentTypes.ALL) {
        load();
        chat.show();
        chat.open();
        AnalyticsTransformer.customEvent(['support', `chat:open`]);
        return;
      }

      chatBlockedByCookieSettingsModalRef.current.open();
    },
  };


  const ctas = callToActions
    ?.map((cta: ModalCtaConfig) => {
      // if (!cta.is_public && !isAuthenticated) return null;
      return (
        <CallToActionButton
          key={cta.id}
          className={`cue-cta-overlay-btn ${cta.call_to_actions_id.action} ${cta?.call_to_actions_id.device?.map(device=>`cue-cta-overlay-btn-device-${device}`).join(" ")}`}
          {...cta.call_to_actions_id}
          onClick={() => {
            const clickAction = clickActions[cta.call_to_actions_id.action];
            if (!clickAction) {
              console.warn(`No CTA Click action defined for "${cta.call_to_actions_id.action}"`);
            }

            clickAction(cta.call_to_actions_id);
          }}
        />
      );
    })
    .filter(Boolean) as React.ReactNode[] | undefined;
    console.log("ctas", callToActions)

  return (
    <>
      {props.children}
      {ctas && ctas?.length > 0 ? (
        <PageOverlayContainer className="cue-cta-overlay">{ctas}</PageOverlayContainer>
      ) : null}
      <Modal ref={adHocModalRef}>{/* <AdhocTalkRequest isLoggedIn={isAuthenticated} /> */}</Modal>
      <Modal ref={chatBlockedByCookieSettingsModalRef}>
        <div className="crisp-chat-blocked-modal">
          <Heading type="h3">{t('crispchat.blocked.headline')}</Heading>
          <Mdx>{t('crispchat.blocked.message')}</Mdx>
        </div>
      </Modal>
    </>
  );
};

const PageOverlayContainer = styled.div(({ theme: { mq } }) => ({
  position: 'fixed',
  bottom: '1rem',
  right: '1rem',
  zIndex: 99,
  display: 'flex',
  justifyContent: 'flex-end',
  flexWrap: 'wrap',
  [mq['md']]: {
    bottom: '2.5rem',
    right: '2.5rem',
  },
  '.ctaOverlayButton': {
    display: 'none',
  },
  '.ctaOverlayButton:first-of-type': {
    display: 'block',
  },
}));
