import { Button, Text } from '@cue/atoms';
import { useQueryParams } from '@cue/hooks';
import { SlideIn, useSlideIn } from '@cue/organisms';
import { StyledComponent } from '@cue/theme';
import { LocalStorageUtil } from '@cue/utility';
import styled, { CSSObject } from '@emotion/styled';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import parse from 'html-react-parser';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const COOKIE_CONSENT_TYPE = 'cookieConsent';
export enum ConsentTypes {
  ALL = 'all',
  REQUIRED = 'required',
}

export const CookieConsent: React.FC<StyledComponent<CookieConsentCSSOverride>> = () => {
  const [show, setShow] = useState(false);
  const [cookieConsentRef] = useSlideIn();
  const { t } = useTranslation();
  const location = useLocation();
  const [params, setParams] = useQueryParams({ navigate, location });

  useEffect(() => {
    checkConsent();
  }, []);

  useEffect(() => {
    if (show) {
      cookieConsentRef.current?.open();
    } else {
      cookieConsentRef.current?.close();
    }
  }, [show, cookieConsentRef]);

  React.useEffect(() => {
    if (params[COOKIE_CONSENT_TYPE] === 'open') {
      cookieConsentRef.current?.open();
    } /*  else {
      cookieConsentRef.current?.close();
    } */
  }, [params, cookieConsentRef]);

  const setConsent = (type: ConsentTypes) => {
    LocalStorageUtil.set<ConsentTypes>(COOKIE_CONSENT_TYPE, type);
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const { [COOKIE_CONSENT_TYPE]: cookieConsent, ...restOfParams } = params;
    /* eslint-enable @typescript-eslint/no-unused-vars */
    setParams({ ...restOfParams });
    setShow(false);
    checkConsent();
    setTimeout(() => {
      window.location.reload();
    }, 250);
  };

  const checkConsent = () => {
    const consent = LocalStorageUtil.get<ConsentTypes>(COOKIE_CONSENT_TYPE);
    if (!consent) {
      setShow(true);
    } else if (consent === ConsentTypes.ALL) {
      try {
        window._paq.push(['setConsentGiven']);
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <SlideIn placement="bottom" ref={cookieConsentRef} hideCloseButton={true}>
      <Content className="cue-project-cookie-consent">
        <Column>
          <Text>
            <strong>{t('cookies.headline')}</strong>
          </Text>
          <Text>{t('cookies.copy')}</Text> <Text>{parse(t('cookies.copy-bottom'))}</Text>
        </Column>
        <Column className="cue-project-cookie-consent-buttons">
          <Button onClick={() => setConsent(ConsentTypes.ALL)}>{t('cookies.cta-full')}</Button>
          <Button styling="secondary" onClick={() => setConsent(ConsentTypes.REQUIRED)}>
            {t('cookies.cta-required')}
          </Button>
        </Column>
      </Content>
    </SlideIn>
  );
};

export type CookieConsentCSSOverride = {
  '.cue-project-cookie-consent'?: CSSObject;
  '.cue-project-cookie-consent-buttons'?: CSSObject;
};
const Content = styled.div(
  ({ theme: { mq } }) => `
flex-direction:columns;
${mq?.['md']}{
  display: flex;
  gap: 40px;
}
`
);
const Column = styled.div(
  ({ theme: { mq } }) => `
&.cue-project-cookie-consent-buttons {

  ${mq?.['md']}{
    width: 40vw;
  }
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: flex-end;
  button {
    ${mq?.['md']}{
      white-space: nowrap;
    }    
    span {
      width: 100%;
      text-align: center;
    }
  }
}
`
);
