import CategoriesJSON from '../../../local-data/json/categories.json';
import { CategoryId, Category_Flat, Category_Tree_Element } from '@project/cms/modules';

function generateCategories(
  cats: Category_Flat[],
  parent_id: CategoryId = null
): Category_Tree_Element[] {
  return (
    cats
      .filter((c) => c.parent_id === parent_id)
      .map((c) => ({
        ...c,
        children: generateCategories(cats, (parent_id = c.id)),
      }))
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .sort((a, b) => (a.sort > b.sort ? 1 : -1))
  );
}

export const useCategories = () => {
  const categories = CategoriesJSON;
  const categoryTree = generateCategories(categories);
  return { categories: categoryTree, categoriesFlat: categories };
};
