import { apiConfig, apisURIs } from './api-config';
import { ProgramEvent } from '@cue/api';

interface Languages {
  [language: string]: string;
}
/* interface EventPhase {
  name: string
  time: Moment.moment
} */

/* eslint-disable */

// *** Separation of GoLocationStrings and EventTypeBehaviour ***
// The location strings can be freely chosen, usually only "on-site" and "virtual", but they can vary, be less, more or be missing completely.
// The EventTypeBehaviour is tied to the code logic of a type of event (online, offline) ... what buttons to display etc.
// These two properties can be freely mapped, so that it is possible to add any new GoLocationStrings and map them to the desired EventTypeBehaviour.

// Locations Strings from go (can be anything, but usually do not change)
// This information comes from Slot Pattern in go
export enum GoLocationString {
  Virtual = 'virtual',
  OnSite = 'on-site',
}

// Behaviour of an event: e.g. what Buttons to display etc.
export enum EventTypeBehaviour {
  Offline = 'offline',
  OnlineSession = 'onlineSession',
}

export type EventBreakEvent = Pick<
  ProgramEvent,
  'title' | 'start' | 'end' | 'categories' | 'isBreak'
>;
/* eslint-enable */

export interface EventConfig {
  utcOffset: number;
  vodCanPlayOffset: number;
  timezone: string;
  timezoneShort: string;
  defaultSource: string;
  sources?: Languages;
  participationWarning: number;
  sessionAvailable: number; // session button active in minutes before event.start
  reservationCancelable: number; // cancel button disabled in minutes after event.start
  defaultLocation: GoLocationString; // location string if event.locaiton === null
  locationToBevahiourMap: Record<GoLocationString, EventTypeBehaviour | null>;
  testSession?: string;
  mainAgendaUrl?: Record<string, string>;
}

export interface ITeaserElements {
  imageUrl: string;
  url: string;
  name: string;
  active?: boolean;
}

export const TEASER_ASPECT_RATIO_X = 4;
export const TEASER_ASPECT_RATIO_Y = 2;
export const TEASER_BORDER_RADIUS = 4;
export const apiUri = apisURIs.events;

export const eventConfig: EventConfig = {
  utcOffset: 60,
  vodCanPlayOffset: 5,
  timezone: 'Europe/Berlin',
  timezoneShort: 'CEST',
  participationWarning: 5,
  sessionAvailable: 15,
  reservationCancelable: 5,
  defaultLocation: GoLocationString.Virtual,
  locationToBevahiourMap: {
    [GoLocationString.OnSite]: EventTypeBehaviour.Offline,
    [GoLocationString.Virtual]: EventTypeBehaviour.OnlineSession,
  },
  defaultSource: apiConfig.defaultEventSource,
  // sources: {
  //   de: `${apiUri}/events.json`,
  //   en: `${apiUri}/events.json`,
  // },
  testSession: 'https://meet.virtualevent.siemens.com/testsession',
  mainAgendaUrl: {
    de: '/de/agenda/',
    en: '/en/agenda/',
  },
};
