import type { SVGProps } from 'react';

const SvgStarFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth={0}
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    className="active"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M21.8,9.6l-7.1-0.8L11.9,2L9.1,8.8L2,9.6l5.3,5l-1.5,7.3l6.1-3.8l6.1,3.8l-1.5-7.3L21.8,9.6z" />
  </svg>
);
export default SvgStarFilled;
