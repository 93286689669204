import type { SVGProps } from 'react';

const SvgEdit = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 13L15 2L20 7L9 18H4V13ZM17.172 7L15 4.828L6 13.828V16H8.172L17.172 7Z"
    />
    <path d="M22 22V20H2V22H22Z" />
  </svg>
);
export default SvgEdit;
