import { ProgramService, ProgramServiceApiConfig } from '@cue/api';
import { useConfig } from '@project/hooks/use-config';

export const useProgramService = () => {
  const config = useConfig();

  if (!config) {
    console.error(`ProgramService can only be used within a ConfigProvider.`);
  }
  if (!config?.apiConfig) {
    console.error(`Config must have an apiConfig property`);
  }

  const programService = new ProgramService(config?.apiConfig as ProgramServiceApiConfig);
  return programService;
};
