import { useMq } from '@cue/hooks';
import React, { useEffect } from 'react';

export const useResponsive = () => {
  const [isPortrait, setIsPortrait] = React.useState<boolean>();
  const isLandscape = !isPortrait;
  const orientation = isPortrait ? 'portrait' : 'landscape';
  const { getMq } = useMq();
  const isBrowser = typeof window !== 'undefined';
  const [windowWidth, setWindowWidth] = React.useState<number>(() => {
    return isBrowser ? window.innerWidth : 0;
  });
  const [windowHeight, setWindowHeight] = React.useState<number>(() => {
    return isBrowser ? window.innerHeight : 0;
  });

  const isMobile = windowWidth < (getMq('md')?.screenSize ?? 0);
  const isLarge = windowWidth > (getMq('lg')?.screenSize ?? 0);

  useEffect(() => {
    function getWindowWidth() {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    }
    window.addEventListener('resize', getWindowWidth);
    getWindowWidth();
    return () => {
      window.removeEventListener('resize', getWindowWidth);
    };
  }, []);

  useEffect(() => {
    const mediaQueryList = window.matchMedia('(orientation: portrait)');
    setIsPortrait(mediaQueryList.matches ? true : false);

    function handleOrientationChange() {
      const mediaQueryList = window.matchMedia('(orientation: portrait)');
      setIsPortrait(mediaQueryList.matches ? true : false);
    }

    mediaQueryList.addEventListener('change', handleOrientationChange);

    return () => {
      mediaQueryList.removeEventListener('change', handleOrientationChange);
    };
  }, []);

  return { isPortrait, isLandscape, orientation, windowWidth, windowHeight, isMobile, isLarge };
};
