import type { SVGProps } from 'react';

const SvgBookmarkMarked = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M 19 2 L 6 2 L 6 22 L 12.5 17 L 19 22 L 19 2 Z"
    />
  </svg>
);
export default SvgBookmarkMarked;
