/* eslint-disable */
// TODO eslint
// @ts-ignore
import { ProgramEvent } from '@cue/api';
import { Dayjs, TimeUtil } from '@cue/utility';
import { useUser } from '@project/hooks/use-user';
import { useUserSubscriptions } from '@project/hooks/use-user-subscriptions';
import jsonLogic from 'json-logic-js';
import React from 'react';

const dayjs = TimeUtil.getDayjs();

jsonLogic.add_operation('isInFuture', (date: string | Dayjs) => {
  // @ts-ignore
  return dayjs(date).isSameOrAfter(TimeUtil.getNow());
});

jsonLogic.add_operation('isInPast', (date: string | Dayjs) => {
  // @ts-ignore
  return dayjs(date).isSameOrBefore(TimeUtil.getNow());
});

jsonLogic.add_operation('userIsParticipant', (eventId: string, participationIds: string[] = []) => {
  return participationIds.includes(eventId);
});

jsonLogic.add_operation(
  'userIsNotParticipant',
  (eventId: string, participationIds: string[] = []) => {
    return !participationIds.includes(eventId);
  }
);

jsonLogic.add_operation('userHasBookmarked', (eventId: string, bookmarks: string[] = []) => {
  return bookmarks.includes(eventId);
});

jsonLogic.add_operation('userHasNotBookmarked', (eventId: string, bookmarks: string[] = []) => {
  return !bookmarks.includes(eventId);
});

jsonLogic.add_operation(
  'isInUsersInterests',
  (categories: { slug: string }[], interests: string[] = []) => {
    if (!interests.length) {
      return true;
    }

    return categories.some((c) => interests.includes(c.slug));
  }
);

jsonLogic.add_operation(
  'matchesUsersParticipation',
  (eventLocation: string, participation: string[] = []) => {
    return participation.includes(eventLocation);
  }
);

function useEventFilter() {
  const { user } = useUser();

  const [participationsLoading, setParticipationsLoading] = React.useState<boolean>(true);
  const [interests, setInterests] = React.useState<string[] | undefined>();
  const [participation /* , setParticipation */] = React.useState<string[] | undefined>();
  const {
    participations: { data: participations },
  } = useUserSubscriptions();

  const {
    bookmarks: { data: bookmarks },
  } = useUserSubscriptions();

  React.useEffect(() => {
    if (user) {
      // @ts-ignore
      try {
        const interests = user?.fields?.interests ? JSON.parse(user?.fields?.interests) : [];
        setInterests(interests);
        /*  const participationArray = [
      // @ts-ignore
      user?.person.onsiteparticipation === 'on' ? 'on-site' : undefined,
      // @ts-ignore
      user?.person.virtualparticipation === 'on' ? 'virtual' : undefined,
    ].filter(Boolean) as string[] | undefined;
    setParticipation(participationArray); */

        /* 
      user && participations;
      !userLoading && !user &&  */
        setParticipationsLoading(false);
      } catch (e) {
        console.error(e);
        setInterests([]);
        setParticipationsLoading(false);
      }
    }
  }, [user, participations]);

  const prepareFilter = React.useCallback(
    (filter: jsonLogic.RulesLogic<jsonLogic.AdditionalOperation>) => {
      if (!filter) {
        return;
      }
      const topLevelKeys = Object.keys(filter);

      return topLevelKeys.reduce(
        (result: jsonLogic.RulesLogic<jsonLogic.AdditionalOperation>, key: any) => {
          // TODO tsc
          // @ts-ignore
          const rules = filter[key].map((r: any) => {
            const ruleKey = jsonLogic.get_operator(r);

            switch (ruleKey) {
              case 'userIsParticipant':
              case 'userIsNotParticipant': {
                return {
                  [ruleKey]: [...r[ruleKey], participations ? Object.keys(participations) : []],
                };
              }
              case 'userHasBookmarked':
              case 'userHasNotBookmarked': {
                return {
                  [ruleKey]: [...r[ruleKey], bookmarks ? bookmarks : []],
                };
              }
              case 'isInUsersInterests': {
                return {
                  [ruleKey]: [...r[ruleKey], interests ? interests : []],
                };
              }

              case 'matchesUsersParticipation': {
                return {
                  [ruleKey]: [...r[ruleKey], participation ? participation : []],
                };
              }

              default:
                return r;
            }
          });

          return { [key]: rules };
        },
        {}
      );
    },
    [interests, bookmarks, participations]
  );

  const filterEvents = React.useCallback(
    (events: ProgramEvent[], filter: jsonLogic.RulesLogic<jsonLogic.AdditionalOperation>) => {
      const preparedFilter = prepareFilter(filter);

      return events.filter((e) => {
        try {
          return preparedFilter ? jsonLogic.apply(preparedFilter, e) : null;
        } catch (e) {
          const err = e as Error;
          console.warn(err.message);

          return true;
        }
      });
    },
    [participations, bookmarks, prepareFilter]
  );

  return {
    filterEvents,
    loading: participationsLoading,
  };
}

export type EventFilterRule = jsonLogic.RulesLogic<jsonLogic.AdditionalOperation>;

export { useEventFilter };
/* eslint-enable */
