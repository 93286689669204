import type { SVGProps } from 'react';

const SvgStar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth={0}
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M21.8,9.6l-7.1-0.8L11.9,2L9.1,8.8L2,9.6l5.3,5l-1.5,7.3l6.1-3.8l6.1,3.8l-1.5-7.3L21.8,9.6z M14.6,14.9l0.6,2.8l-2.3-1.4l-1-0.6l-1,0.6l-2.3,1.4l0.6-2.8l0.2-1l-0.8-0.8l-2.1-2l2.8-0.3l1.2-0.1l0.5-1.1l1-2.5l1,2.5l0.4,1.1l1.2,0.1l2.8,0.3l-2.1,2l-0.8,0.8L14.6,14.9z" />
  </svg>
);
export default SvgStar;
