import type { SVGProps } from 'react';

const SvgBookmark = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 2H6V22L12.5 17L19 22V2ZM17 4V17.938L12.5 14.477L8 17.938V4H17Z"
    />
  </svg>
);
export default SvgBookmark;
