import { Auth } from '../../../cue-api/auth';
import { Button, Heading, Input, InputProps, Paragraph } from '@cue/atoms';
import { useForm } from '@cue/hooks';
import { StyledComponent } from '@cue/theme';
import { AnalyticsTransformer } from '@cue/utility';
import styled, { CSSObject } from '@emotion/styled';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

interface ResendAccesModalForm {
  email: string;
}
type States = 'init' | 'success' | 'error';

export const ResendAccesModal: React.FC<StyledComponent<ResendAccesModalCSSOverride>> = () => {
  const auth = React.useMemo(() => new Auth(), []);
  const [state, setState] = React.useState<States>('init');

  const { t } = useTranslation();
  const schema = yup
    .object({
      email: yup
        .string()
        .email('Wrong Email Format')
        .when('showEmail', ([showEmail], yupSchema) => {
          return showEmail ? yupSchema.required('Email is required') : yupSchema;
        })
        .required(),
    })
    .required();

  const {
    form: { formState, handleSubmit, watch, trigger },
    registerElement,
  } = useForm<ResendAccesModalForm>({
    schema,
    defaultValues: {
      email: '',
    },
  });

  const showEmailValue = watch('email');
  useEffect(() => {
    // revalidate if show email changes
    trigger();
  }, [showEmailValue, trigger]);

  const goToRegForm = React.useCallback(() => {
    AnalyticsTransformer.customEvent(['registerModal', `regForm`]);

    window.open(t('no-token.to-registration.uri').toString(), '_blank', 'noopener');
  }, [t]);

  const onSubmit = async (values: ResendAccesModalForm) => {
    const resendResult = await auth.resendToken(values.email);
    if (resendResult.status === 204) {
      setState('success');
      AnalyticsTransformer.customEvent(['registerModal', `resend:success`]);
    } else {
      setState('error');
      AnalyticsTransformer.customEvent(['registerModal', `resend:error`]);
    }
  };

  return (
    <Container className="project-resend-access">
      <Heading type="h5">{t('no-token.headline')}</Heading>
      <>
        {state === 'init' && (
          <>
            <p>{t('no-token.copy')}</p>
            {registerElement<InputProps>({
              element: Input,
              name: 'email',
              props: {
                placeholder: 'E-Mail',
                error: formState.isSubmitted && formState.errors.email !== undefined,
              },
            })}
            <ButtonContainer className="project-resend-access-button">
              <Button onClick={handleSubmit(onSubmit)} className="project-resend-access-submit">
                {t('no-token.cta.label')}
              </Button>
            </ButtonContainer>
            <hr />
            <ToRegistration onClick={goToRegForm} className="project-resend-registration-link">
              {t('no-token.to-registration.label')}
            </ToRegistration>
          </>
        )}
        {state === 'success' && <Paragraph>{t('no-token.cta.response')}</Paragraph>}

        {state === 'error' && <Paragraph>{t('no-token.cta.error')}</Paragraph>}
      </>
    </Container>
  );
};
const ToRegistration = styled(Paragraph)(() => ({
  marginTop: '1rem',
  marginBottom: 0,
  display: 'inline-block',
  textDecoration: 'underline',
  cursor: 'pointer',

  '&:hover': {},
}));
const Container = styled.div``;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export type ResendAccesModalCSSOverride = {
  '.project-resend-access'?: CSSObject;
  '.project-resend-access-submit'?: CSSObject;
};
