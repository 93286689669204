import type { SVGProps } from 'react';

const SvgInstagram = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M18.54 6.66C18.54 7.32274 18.0027 7.86 17.34 7.86C16.6773 7.86 16.14 7.32274 16.14 6.66C16.14 5.99726 16.6773 5.46 17.34 5.46C18.0027 5.46 18.54 5.99726 18.54 6.66Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.86 12C6.86 9.16126 9.16126 6.86 12 6.86C13.3632 6.86 14.6706 7.40153 15.6345 8.36547C16.5985 9.32941 17.14 10.6368 17.14 12C17.14 14.8387 14.8387 17.14 12 17.14C9.16126 17.14 6.86 14.8387 6.86 12ZM8.67 12C8.67 13.8391 10.1609 15.33 12 15.33C12.8832 15.33 13.7302 14.9792 14.3547 14.3547C14.9792 13.7302 15.33 12.8832 15.33 12C15.33 10.1609 13.8391 8.67 12 8.67C10.1609 8.67 8.67 10.1609 8.67 12Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C9.28 2 8.94 2 7.88 2.06C7.0503 2.07945 6.2294 2.23484 5.45 2.52C4.78181 2.77166 4.17742 3.16776 3.68 3.68C3.16776 4.17742 2.77166 4.78181 2.52 5.45C2.23484 6.2294 2.07945 7.0503 2.06 7.88C2 8.94 2 9.28 2 12C2 14.72 2 15.06 2.06 16.12C2.07945 16.9497 2.23484 17.7706 2.52 18.55C2.77166 19.2182 3.16776 19.8226 3.68 20.32C4.17742 20.8322 4.78181 21.2283 5.45 21.48C6.2294 21.7652 7.0503 21.9206 7.88 21.94C8.94 22 9.28 22 12 22C14.72 22 15.06 22 16.12 21.94C16.9497 21.9206 17.7706 21.7652 18.55 21.48C19.8911 20.9525 20.9525 19.8911 21.48 18.55C21.7652 17.7706 21.9206 16.9497 21.94 16.12C21.94 15.7127 21.9489 15.4116 21.9598 15.041C21.9773 14.4472 22 13.6745 22 12C22 9.28 22 8.94 21.94 7.88C21.9206 7.0503 21.7652 6.2294 21.48 5.45C21.2283 4.78181 20.8322 4.17742 20.32 3.68C19.8226 3.16776 19.2182 2.77166 18.55 2.52C17.7706 2.23484 16.9497 2.07945 16.12 2.06C15.06 2 14.72 2 12 2ZM12 3.8C14.67 3.8 15 3.8 16 3.86C16.6348 3.86728 17.2637 3.98225 17.86 4.2C18.7321 4.53836 19.4216 5.22792 19.76 6.1C19.9959 6.7065 20.1245 7.34942 20.14 8C20.19 9 20.2 9.37 20.2 12C20.2 14.6298 20.2 15 20.14 16C20.1327 16.6348 20.0178 17.2637 19.8 17.86C19.4616 18.7321 18.7721 19.4216 17.9 19.76C17.3037 19.9778 16.6748 20.0927 16.04 20.1C15.04 20.15 14.67 20.16 12.04 20.16C9.41 20.16 9.04 20.16 8.04 20.1C7.38073 20.1148 6.72401 20.0132 6.1 19.8C5.22792 19.4616 4.53836 18.7721 4.2 17.9C3.97775 17.2911 3.86271 16.6482 3.86 16C3.86 15.6186 3.85127 15.3288 3.84047 14.9703C3.82296 14.3889 3.8 13.6268 3.8 12C3.8 9.37 3.8 9 3.86 8C3.86271 7.35178 3.97775 6.70893 4.2 6.1C4.53836 5.22792 5.22792 4.53836 6.1 4.2C6.70893 3.97775 7.35178 3.86271 8 3.86C8.38143 3.86 8.6712 3.85127 9.02969 3.84047C9.61106 3.82296 10.3732 3.8 12 3.8Z"
    />
  </svg>
);
export default SvgInstagram;
