import { PageContext } from '../../../gatsby-node';
import { ResendAccesModal } from '../components/resend-acess-modal';
import { SiemensChatBot } from '../components/siemens-chat-bot';
import { CallToActionOverlay } from './call-to-action-overlay';
import { CookieConsent } from './cookie-consent';
import { QueryParamTrigger } from './query-param-trigger';
import { Button } from '@cue/atoms';
import { Modal, useModal } from '@cue/organisms';
import { AnalyticsTransformer, LoadAnalytics } from '@cue/utility';
import { useUser } from '@project/hooks/use-user';
import { PageProps } from 'gatsby';
import React, { useEffect } from 'react';

type ProjectSpecificPageWrapperProps = PageProps<
  Record<string, unknown>,
  Record<string, unknown>,
  unknown,
  object
> & {
  children?: React.ReactNode;
  pageContext?: PageContext;
};

// Add Specific Pages Wrapper here

export const ProjectSpecificPageWrapper: React.FC<ProjectSpecificPageWrapperProps> = (props) => {
  const { user, loading: userLoading } = useUser();
  const [reauthModal] = useModal();
  useEffect(() => {
    if (user === false) {
      //reauthModal.current?.open();
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.reauthModal = reauthModal;
  }, [userLoading, user, reauthModal]);

  const includeSiemensChatBot =
    props.pageContext?.globalOptions?.include_siemens_chat_bot === 'true' &&
    props.pageContext.pageInfo.includeSiemensChatBot;

  useEffect(() => {
    if (!userLoading && user) AnalyticsTransformer.setUserData();
  }, [userLoading, user]);

  return (
    <QueryParamTrigger>
      <Modal
        ref={reauthModal}
        customCloseRenderer={({ onClose }) => (
          <Button
            icon="close"
            styling="modalClosePlain"
            onClick={() => {
              AnalyticsTransformer.customEvent(['registerModal', `close`]);
              onClose && onClose();
            }}
          />
        )}>
        <ResendAccesModal />
      </Modal>
      <CallToActionOverlay {...props}>{props.children}</CallToActionOverlay>
      {!userLoading && <LoadAnalytics />}
      <CookieConsent />
      {includeSiemensChatBot ? <SiemensChatBot showOn="all-not-mobile" /> : null}
    </QueryParamTrigger>
  );
};
