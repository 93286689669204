import type { SVGProps } from 'react';

const SvgInformation = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M12 6.67C11.6705 6.65603 11.3501 6.7794 11.115 7.01066C10.8799 7.24193 10.7514 7.56036 10.76 7.89C10.718 8.35408 10.9419 8.80202 11.3383 9.04703C11.7346 9.29205 12.2354 9.29205 12.6317 9.04703C13.0281 8.80202 13.252 8.35408 13.21 7.89C13.2211 7.5649 13.0976 7.24962 12.8685 7.01866C12.6394 6.78769 12.3252 6.66154 12 6.67Z" />
    <path d="M13.06 17.23V11H10.94V17.23H13.06Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 12C2 6.47715 6.47715 2 12 2C14.6522 2 17.1957 3.05357 19.0711 4.92893C20.9464 6.8043 22 9.34784 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM4 12C4 16.4183 7.58172 20 12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12Z"
    />
  </svg>
);
export default SvgInformation;
