import type { SVGProps } from 'react';

const SvgChat1 = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 2H22V17H16V22L8 17H2V2ZM20 15V4H4V15H8.57L9.05 15.3L14.05 18.41V15H20Z"
    />
  </svg>
);
export default SvgChat1;
