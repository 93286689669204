import { useEvents } from '../use-events/use-events';
import { EventFilterRule, useEventFilter } from './utilities/use-json-logic';
import { ProgramEvent } from '@cue/api';
import { SlideIn, useSlideIn } from '@cue/organisms';
import styled from '@emotion/styled';
import diff from 'deep-diff';
import React from 'react';

export type UseEventSuggestionsOptions = {
  itemsNeeded?: number;
  shuffled?: boolean;
  filter?: EventFilterRule;
  priority?: EventFilterRule[];
  excludeIds?: string[];
};

type EventSuggestionDebuggerProps = {
  // TODO
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: Record<string, any>[];
};

function EventSuggestionDebugger(props: EventSuggestionDebuggerProps) {
  return (
    <DebugResults>
      {props?.data?.map((d, i) => (
        <div key={i}>
          <h2 className="title">{d.message}</h2>
          <div className="meta">
            {d.itemsNeeded ? `${d.itemsNeeded} Items needed` : null}{' '}
            {'shuffled' in d ? (d.shuffled ? ' | Shuffled' : 'Not shuffled') : null}
            {typeof d.result === 'number' ? (
              <>
                | <span className="eventCount">{d.result} Event(s)</span>
              </>
            ) : null}
          </div>

          {d.filter ? (
            <div className="filter">
              <strong>Filter:</strong> {JSON.stringify(d.filter, null, 0)}
            </div>
          ) : null}
        </div>
      ))}
    </DebugResults>
  );
}

const DebugResults = styled.div`
  overflow: auto;
  max-height: 85vh;
  background-color: rgba(255, 255, 255, 0.1);
  h2 {
    margin-bottom: 0;
  }

  > div {
    padding: 5px 10px;
  }

  > div:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .title {
    margin-bottom: 5px;
  }
  .meta,
  .filter {
    margin-bottom: 20px;
  }

  .eventCount {
    background-color: #00cccc;
    color: #000028;
    font-weight: 600;
    padding: 0 8px;
  }
`;

export function useEventSuggestions(options: UseEventSuggestionsOptions = {}) {
  const [eventSuggestionOptions, seteventSuggestioOptions] = React.useState(options);

  const { filterEvents, loading: loadingEvents } = useEventFilter();
  const [eventSuggestions, setEventSuggestions] = React.useState<ProgramEvent[]>();
  // TODO
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const deguggerDataRef = React.useRef<Record<string, any>[]>([]);
  const { events } = useEvents();
  const [debuggerRef] = useSlideIn();

  const [regenerationTrigger, setRegenerationTrigger] = React.useState(0);

  const regenerate = React.useCallback(() => {
    setRegenerationTrigger((t) => t + 1);
  }, []);

  const debuggerElement = (
    <SlideIn ref={debuggerRef}>
      <EventSuggestionDebugger data={deguggerDataRef.current} />
    </SlideIn>
  );

  React.useEffect(() => {
    function handleKeyDown(e: KeyboardEvent) {
      if (e.altKey && e.key === '€') {
        debuggerRef.current?.open();
      }
    }
    document.addEventListener('keydown', handleKeyDown);

    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [debuggerRef]);

  React.useEffect(() => {
    // TODO: remove deep-diff dependency
    if (diff(options, eventSuggestionOptions)) {
      seteventSuggestioOptions(options);
    }
  }, [options, eventSuggestionOptions]);

  const createEventSuggestions = React.useCallback(
    (options: UseEventSuggestionsOptions = {}, priorityDepth = 0) => {
      const { itemsNeeded = 1, shuffled = false, filter, priority } = options;

      const result: ProgramEvent[] = [];
      if (!events) {
        deguggerDataRef.current.push({
          priorityDepth,
          itemsNeeded,
          shuffled,
          filter,
          message: 'No events',
        });
        return result;
      }

      // hard filter first
      const filteredEvents = filter ? filterEvents(events, filter) : events;

      if (priorityDepth === 0) {
        // reset
        deguggerDataRef.current = [];
        deguggerDataRef.current.push({
          priorityDepth,
          itemsNeeded,
          shuffled,
          filter,
          result: filteredEvents.length,
          message: 'Potential Events',
        });

        // deguggerDataRef.current.push({
        //   message: `===================================`,
        // });
      }

      // always take priority[priorityDepth] and check
      // if not enought take priority[priorityDepth + 1] and check until no prio filters left
      if (priority) {
        const prioritizedEvents = filterEvents(filteredEvents, priority[priorityDepth]);
        deguggerDataRef.current.push({
          priorityDepth,
          itemsNeeded,
          shuffled,
          filter: priority[priorityDepth],
          result: prioritizedEvents.length,
          message: `Checking priority ${priorityDepth}`,
        });

        if (prioritizedEvents.length) {
          (shuffled
            ? prioritizedEvents.sort(() => 0.5 - Math.random())
            : prioritizedEvents
          ).forEach((newEvent) => {
            if (result.length === itemsNeeded) return;

            if (!result.find((ressultEvent) => ressultEvent.id === newEvent.id)) {
              result.push(newEvent);
            }
          });
        }

        if (result.length < itemsNeeded && priority[priorityDepth + 1]) {
          const nextPriorityDepthEvents = createEventSuggestions(
            { ...options, itemsNeeded: itemsNeeded - result.length },
            priorityDepth + 1
          );

          if (nextPriorityDepthEvents?.length) {
            (shuffled
              ? nextPriorityDepthEvents.sort(() => 0.5 - Math.random())
              : nextPriorityDepthEvents
            ).forEach((newEvent) => {
              if (result.length === itemsNeeded) return;

              if (!result.find((ressultEvent) => ressultEvent.id === newEvent.id)) {
                result.push(newEvent);
              }
            });
          }
        }
      }

      // Fill up with something, if nothing better found on top-level result
      if (priorityDepth === 0 && result.length < itemsNeeded) {
        const eventNotInSuggestions = events
          ?.filter(
            (e: ProgramEvent) => !result.map((eventSuggestion) => eventSuggestion.id).includes(e.id)
          )
          .sort(() => 0.5 - Math.random());

        if (eventNotInSuggestions?.length) {
          const missingEvents = itemsNeeded - result.length;
          const randomFillerEvents = eventNotInSuggestions.slice(0, missingEvents);
          result.push(...randomFillerEvents);
        }
      }

      return result;
    },
    [events, filterEvents]
  );

  React.useEffect(() => {
    if (loadingEvents || !events) return;
    const suggestions = createEventSuggestions(eventSuggestionOptions);
    setEventSuggestions(suggestions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingEvents, events, eventSuggestionOptions, regenerationTrigger]);

  return {
    eventSuggestions,
    events,
    loading: loadingEvents,
    deguggerData: deguggerDataRef.current,
    debuggerElement,
    regenerate,
  };
}
