import { LocalStorageUtil } from '@cue/utility';

export const AGENDA_BOOKMARKS = 'agendaBookmarks';

export class BookmarkService {
  public static async getBookmarks(): Promise<string[]> {
    return (LocalStorageUtil.get<string[]>(AGENDA_BOOKMARKS) || []) as string[];
  }

  public static async setBookmarks(bookmarks: string[]): Promise<string[] | null> {
    LocalStorageUtil.set<string[]>(AGENDA_BOOKMARKS, bookmarks);

    return bookmarks;
  }
}
