import type { SVGProps } from 'react';

const SvgFullScreenExit = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M2 9V7H7V2H9V9H2Z" />
    <path d="M2 15H9V22H7V17H2V15Z" />
    <path d="M22 9H15V2H17V7H22V9Z" />
    <path d="M15 22V15H22V17H17V22H15Z" />
  </svg>
);
export default SvgFullScreenExit;
