import type { SVGProps } from 'react';

const SvgLinkedIn = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.52 2.00002H20.52C20.9072 1.99468 21.2807 2.1434 21.5583 2.41346C21.8358 2.68352 21.9947 3.05279 22 3.44002V20.56C21.9949 20.9544 21.8305 21.3299 21.5442 21.6011C21.2579 21.8724 20.8741 22.0163 20.48 22H3.48C3.09277 22.0054 2.71929 21.8566 2.44173 21.5866C2.16416 21.3165 2.00527 20.9472 2 20.56V3.44002C2.00507 3.04566 2.16946 2.67013 2.45577 2.39889C2.74208 2.12765 3.12594 1.98378 3.52 2.00002ZM5 19H7.93L8 9.50002H5V19ZM6.45 8.19002C5.50007 8.19002 4.73 7.41995 4.73 6.47002C4.73 5.52009 5.50007 4.75002 6.45 4.75002C7.39993 4.75002 8.17 5.52009 8.17 6.47002C8.17 7.41995 7.39993 8.19002 6.45 8.19002ZM16 19H19L18.89 13.81C18.89 11.26 18.33 9.26002 15.33 9.26002C14.1836 9.21569 13.1065 9.80812 12.53 10.8V9.50002H9.68V19H12.68V14.32C12.68 13.08 12.92 11.87 14.46 11.87C16 11.87 16 13.29 16 14.4V19Z"
    />
  </svg>
);
export default SvgLinkedIn;
