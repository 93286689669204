import type { SVGProps } from 'react';

const SvgMenu = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M2 5H22V7H2V5Z" />
    <path d="M2 11H15V13H2V11Z" />
    <path d="M22 17H2V19H22V17Z" />
  </svg>
);
export default SvgMenu;
