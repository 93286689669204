// extracted by mini-css-extract-plugin
export var BLACK = "#000000";
export var BLUE = "#0087be";
export var BLUE_LIGHT3 = "#007993";
export var BOLD_BLUE = "#00e6dc";
export var BOLD_GREEN = "#00ffb9";
export var BOLD_GREEN_12_DB = "#001f39";
export var BOLD_GREEN_45_DB = "#007369";
export var BOLD_GREEN_55_DB = "#008c78";
export var BOLD_GREEN_60_DB = "#00997f";
export var BOLD_GREEN_65_DB = "#00a686";
export var BOLD_GREEN_90_DB = "#00e5aa";
export var BOMBAY = "#b3b3be";
export var BRIGHT_SAND = "#dfdfd9";
export var DARK_BLUE = "#00557c";
export var DARK_GREEN = "#00646e";
export var DARK_PURPLE = "#553ba3";
export var DARK_SAND = "#aaaa96";
export var DEEP_BLUE = "#000028";
export var DEEP_BLUE_10 = "#e5e5e9";
export var DEEP_BLUE_20 = "#ccccd4";
export var DEEP_BLUE_30 = "#b3b3be";
export var DEEP_BLUE_40 = "#9999a9";
export var DEEP_BLUE_50 = "#7d8099";
export var DEEP_BLUE_55 = "#737389";
export var DEEP_BLUE_60 = "#66667e";
export var DEEP_BLUE_70 = "#4c4c68";
export var DEEP_BLUE_8 = "#ebebee";
export var DEEP_BLUE_80 = "#333353";
export var DEEP_BLUE_85 = "#262648";
export var FEEDBACK_BLUE = "#00bedc";
export var FEEDBACK_GREEN = "#01d65a";
export var FEEDBACK_ORANGE = "#ff9000";
export var FEEDBACK_RED = "#ff2640";
export var FEEDBACK_RED_TEXT = "#ff7687";
export var FEEDBACK_YELLOW = "#ffd732";
export var FOCUS_BORDER = "#1491eb";
export var GRADIENT_BLOCK_GRADIENT = "linear-gradient(to left, #00ffb9 0%, #00e6dc 100%)";
export var GRADIENT_BOLD_DYNAMIC_PETROL = "linear-gradient(to left, #00ffb9 0%, #00e6dc 100%)";
export var GRADIENT_DEEP_BLUE_PETROL = "linear-gradient(180deg, #000028 0%, #009999 100%)";
export var GREEN = "#00af8e";
export var INTERACTIVE_CORAL = "#00cccc";
export var INTERACTIVE_CORAL_12_DB = "#00183b";
export var INTERACTIVE_CORAL_20_DB = "#002949";
export var INTERACTIVE_CORAL_50_DB = "#007082";
export var INTERACTIVE_CORAL_70_DB = "#008f9b";
export var INTERACTIVE_CORAL_8_DB = "#001034";
export var LIGHT_BLUE = "#001c31";
export var LIGHT_GREEN_40 = "#c5ffef";
export var LIGHT_PURPLE = "#6a2fe9";
export var LIGHT_SAND = "#f3f3f0";
export var PETROL = "#009999";
export var PURPLE = "#805cff";
export var SHERPA_BLUE = "#005159";
export var SOFT_BLUE = "#00bedc";
export var SOFT_GREEN = "#00d7a0";
export var SOFT_PURPLE = "#b4a8ff";
export var SOFT_SAND = "#c5c5b8";
export var STONE_LIGHT = "#879baa";
export var WHITE = "#ffffff";