import { useResponsive } from '@project/hooks/use-responsive';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const botId = 'dc1425ee-4109-4eae-8bdb-f01522625fc3';
type ShowOptions = 'mobile' | 'all-not-mobile';

export const SiemensChatBot: React.FC<{ showOn: ShowOptions }> = ({ showOn }) => {
  const { i18n, t } = useTranslation();
  const botEnv: 'dev' | 'prod' = 'prod';
  const loginButton = false;
  const logLevel: 'debug' | 'info' | 'warn' | 'error' = 'info';
  const initialMessage = t('chatBot.initialMessage');
  const enableHistory = false;

  const { isMobile } = useResponsive();

  const chatBotSettings = `{
      element: '#webchat',
      botId: '${botId}',
      botEnv: '${botEnv}',
      language: "${i18n.language || 'en'}",
      loginButton: '${loginButton}',
      logLevel: '${logLevel}',
      initialMessage: '${initialMessage}',
      enableHistory: ${enableHistory},
      translationConfiguration: setupTranslationConfiguration("${botId}"),
      userLang:  "${i18n.language || 'en'}",
    }`;

  if (showOn) {
    if (showOn === 'mobile' && !isMobile) {
      return null;
    }
    if (showOn === 'all-not-mobile' && isMobile) {
      return null;
    }
  }

  return (
    <>
      <Helmet>
        {/* <link
          href="https://botbuilder.siemens.cloud/channels/public/dist/styles.css"
          rel="stylesheet"
          type="text/css"
        /> */}
        <script>{`  function initBot() {
           new BotanicaWebchat( ${chatBotSettings})
        }`}</script>
        <script src="https://botbuilder.siemens.cloud/channels/public/dist/botConfig.js" />
        <script src="https://botbuilder.siemens.cloud/channels/public/dist/botanicaWebchat.bundle.js" />
      </Helmet>
      <div id="botbuilder" className={showOn}></div>
    </>
  );
};
