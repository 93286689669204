import { useProgramService } from '../../cue-api/use-program-service';
import { useUser } from '../use-user/use-user';
import { useToastNotification } from '@cue/organisms';
import { BookmarkService } from '@project/cue-api/bookmarks';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

/* This should be THE ONLY place to use ProgramService.eventParticipation(),
/* ProgramService.reserveEvent() &  ProgramService.cancelEvent() !!!!
/* useUserSubscriptions() will keep the participations updated with minimal requests. */

/* This should be THE ONLY place to use BookmarkService.setBookmarks() & BookmarkService.getBookmarks() !!!!
/* useUserSubscriptions() will keep the bookmarks updated for all components. */

/* eslint-disable cuenect-web-rules/no-direct-use-of-program-service */
/* eslint-disable cuenect-web-rules/no-direct-use-of-bookmark-service */
export function useUserSubscriptions() {
  const queryClient = useQueryClient();
  const notification = useToastNotification();
  const { t } = useTranslation();
  const ProgramService = useProgramService();
  const { isAuthenticated } = useUser();

  const {
    data: participations,
    isLoading: lodaingParticipations,
    error: errorParticipations,
    fetchStatus: fetchStatusParticipations,
  } = useQuery({
    queryKey: ['participations'],
    queryFn: () => ProgramService?.eventParticipation(),
    refetchOnMount: false,
    enabled: isAuthenticated,
  });

  const reserveEventMutation = useMutation({
    /* eslint-disable */
    // @ts-ignore
    mutationFn: (eventId: string) => ProgramService?.reserveEvent(eventId),
    /* eslint-enable */
    onSuccess: () => queryClient.invalidateQueries(['participations']),
    onError: () => notification.error({ message: t('agenda.reserve.error') }),
    networkMode: 'always',
  });

  async function reserveEvent(eventId: string) {
    return await reserveEventMutation.mutate(eventId);
  }

  const cancelEventMutation = useMutation({
    /* eslint-disable */
    // @ts-ignore
    mutationFn: (eventId: string) => ProgramService?.cancelEvent(eventId),
    /* eslint-enable */
    onSuccess: () => queryClient.invalidateQueries(['participations']),
    onError: () => notification.error({ message: t('agenda.cancel.error') }),
    networkMode: 'always',
  });

  async function cancelEvent(eventId: string) {
    return await cancelEventMutation.mutate(eventId);
  }

  const {
    data: bookmarks,
    isLoading: isLoadingBookmarks,
    error: errorBookmarks,
    fetchStatus: fetchStatusBookmarks,
  } = useQuery({
    queryKey: ['bookmarks'],
    /* eslint-disable */
    // @ts-ignore
    queryFn: () => BookmarkService.getBookmarks(),
    /* eslint-enable */
    refetchOnMount: false,
  });

  const toggleBookmarkMutation = useMutation({
    mutationFn: BookmarkService.setBookmarks,
    onSuccess: () => queryClient.invalidateQueries(['bookmarks']),
  });

  function toggleBookmark(eventId: string) {
    // Probably this will never happen at this point, but just in case
    if (isLoadingBookmarks) return;

    if (!bookmarks) {
      toggleBookmarkMutation.mutate([eventId]);
    }

    if (!Array.isArray(bookmarks)) return;

    if (bookmarks.includes(eventId)) {
      toggleBookmarkMutation.mutate(bookmarks.filter((id) => id !== eventId));
    } else {
      toggleBookmarkMutation.mutate([...bookmarks, eventId]);
    }
  }

  return {
    participations: {
      data: participations,
      isLoading: lodaingParticipations,
      error: errorParticipations,
      fetchStatus: fetchStatusParticipations,
      reserveEvent,
      reserveEventResult: {
        data: reserveEventMutation.data,
        error: reserveEventMutation.error as Error,
        isLoading: reserveEventMutation.isLoading,
        isSuccess: reserveEventMutation.isSuccess,
        isPaused: reserveEventMutation.isPaused,
        failureCount: reserveEventMutation.failureCount,
        failureReason: reserveEventMutation.failureReason,
      },
      cancelEvent,
      cancelEventResult: {
        data: cancelEventMutation.data,
        error: cancelEventMutation.error as Error,
        isLoading: cancelEventMutation.isLoading,
        isSuccess: cancelEventMutation.isSuccess,
        isPaused: cancelEventMutation.isPaused,
        failureCount: cancelEventMutation.failureCount,
        failureReason: cancelEventMutation.failureReason,
      },
    },
    toggleBookmark,
    bookmarks: {
      data: bookmarks,
      isLoading: isLoadingBookmarks,
      error: errorBookmarks,
      fetchStatus: fetchStatusBookmarks,
    },
    isLoading:
      lodaingParticipations ||
      isLoadingBookmarks ||
      reserveEventMutation.isLoading ||
      cancelEventMutation.isLoading,
    error:
      errorParticipations ||
      errorBookmarks ||
      reserveEventMutation.error ||
      cancelEventMutation.error,
  };
}

/* eslint-enable cuenect-web-rules/no-direct-use-of-bookmark-service */
/* eslint-enable cuenect-web-rules/no-direct-use-of-program-service */
