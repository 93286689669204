import type { SVGProps } from 'react';

const SvgFilter2 = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <line id="Line 127" x1={8.74228e-8} y1={1} x2={20} y2={1} stroke="white" strokeWidth={2} />
    <line id="Line 128" x1={3} y1={6} x2={17} y2={6} stroke="white" strokeWidth={2} />
    <line id="Line 129" x1={7} y1={11} x2={13} y2={11} stroke="white" strokeWidth={2} />
  </svg>
);
export default SvgFilter2;
