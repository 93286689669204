import { Flag, FlagDate } from './types';
import { TimeUtil } from '@cue/utility';
import FlagsJSON from '@project/local/flags.json';
import React from 'react';

export interface FeatureFlagProviderProps {
  featureFlags: FeatureFlags;
}

export interface FeatureFlags {
  [name: string]: boolean;
}

export enum FlagDateTypes_Enum {
  DateRange = 'date_range',
  Before = 'before',
  After = 'after',
}

export enum FlagTypes_Enum {
  Date = 'dates',
  Boolean = 'bool',
}

type RawFlag = Omit<Flag, 'dates'> & {
  dates: {
    dates_id: FlagDate;
  }[];
};

type FlagsState = {
  [slug: string]: boolean;
};

export const FlagsContext = React.createContext<FlagsState>({});

type FlagsProviderProps = {
  children?: React.ReactNode;
};

export const FlagsProvider: React.FC<FlagsProviderProps> = ({ children }) => {
  const [flagsState, setFlagsState] = React.useState<FlagsState>({});

  const flag = FlagsJSON;
  console.log('flagflag', process.env.ENVIRONMENT, flag);
  // Prepare flags because API output is weird with dates > date: { dates_id: { ...realDateInfo }}[]
  const flags: Flag[] | undefined = React.useMemo(() => {
    if (!flag) return;

    return (flag as RawFlag[])
      .map((f) => ({
        ...f,
        dates: f.dates.map((d) => d.dates_id),
      }))
      .filter((f) => {
        if (!f.environment) return true;
        if (f.environment === process.env.ENVIRONMENT) return true;
        return false;
      });
  }, [flag]);

  // flag to { [key: string]: boolean } && update flagsState
  const checkDateFlags = React.useCallback(() => {
    if (!flags) return;

    const newExernalState: { [key: string]: boolean } = {};

    flags.forEach((f: Flag) => {
      switch (f.type) {
        case FlagTypes_Enum.Boolean:
          if (typeof f.boolean === 'boolean') newExernalState[f.slug] = f.boolean;
          break;
        case FlagTypes_Enum.Date:
          newExernalState[f.slug] = getBooleanValueForDateFlag({
            ...f,
            dates: f.dates,
          });
          break;

        default:
          break;
      }
    });

    const stateNeedsUpdate = Object.keys(newExernalState).some(
      (key) => newExernalState[key] !== flagsState[key]
    );

    if (stateNeedsUpdate) {
      setFlagsState(newExernalState);
    }
  }, [flags, flagsState]);

  // initial check
  React.useEffect(() => {
    if (flags) {
      checkDateFlags();
    }
  }, [flags, checkDateFlags]);

  // Timer to check regularly if some time Flag is true
  const timer = React.useRef<ReturnType<typeof setInterval>>();

  React.useEffect(() => {
    if (!flag) return;
    timer.current && clearInterval(timer.current);
    timer.current = setInterval(checkDateFlags, 5000);

    return () => timer.current && clearInterval(timer.current);
  }, [flag, flagsState, checkDateFlags]);

  return <FlagsContext.Provider value={flagsState}>{children}</FlagsContext.Provider>;
};

export const useFlag = (slug: string | undefined | null) => {
  const context = React.useContext(FlagsContext);
  if (!slug) return null;
  if (!context) {
    console.warn(`useFlag must be used within FlagsProvider`);
  }

  if (Object.keys(context).length === 0) {
    return null;
  }

  if (context[slug] === undefined) {
    console.warn(`You are trying to use a Flag with the slug "${slug}" that does not exist!`);
  }

  return context[slug];
};

function getBooleanValueForDateFlag(dateFlag: Flag) {
  const now = TimeUtil.getNowUtc();

  const bool = dateFlag.dates.map((date) => {
    if (!date) {
      console.warn(`getBooleanValueForDateFlag: Missing date info`, JSON.stringify(date));
      return;
    }
    switch (date.type) {
      case FlagDateTypes_Enum.DateRange: {
        if (date.start_date && date.end_date) {
          return now.isBetween(TimeUtil.getUtc(date.start_date), TimeUtil.getUtc(date.end_date));
        }
        console.warn(`${dateFlag.slug} is missing dates`, date.start_date, date.end_date);
        break;
      }
      case FlagDateTypes_Enum.Before: {
        if (date.before_date) {
          return now.isBefore(TimeUtil.getUtc(date.before_date));
        }
        console.warn(`${dateFlag.slug} is missing before_date`, date.before_date);
        break;
      }
      case FlagDateTypes_Enum.After: {
        if (date.after_date) {
          return now.isAfter(TimeUtil.getUtc(date.after_date));
        }
        console.warn(`${dateFlag.slug} is missing after_date`, date.after_date);
        break;
      }
    }
  });

  return bool.some((dateCheck) => dateCheck === true);
}
