import type { SVGProps } from 'react';

const SvgBuildings = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M7 6H9V8H7V6Z" />
    <path d="M9 10H7V12H9V10Z" />
    <path d="M7 14H9V16H7V14Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 2H13V20H19V8H15V6H21V20H22V22H2V20H3V2ZM5 4H11V20H9V18H7V20H5V4Z"
    />
    <path d="M15 10H17V12H15V10Z" />
    <path d="M17 14H15V16H17V14Z" />
  </svg>
);
export default SvgBuildings;
