import type { SVGProps } from 'react';

const SvgFullScreen = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M9 2V4H4V9H2V2H9Z" />
    <path d="M4 15V20H9V22H2V15H4Z" />
    <path d="M15 2V4H20V9H22V2H15Z" />
    <path d="M20 20V15H22V22H15V20H20Z" />
  </svg>
);
export default SvgFullScreen;
