import type { SVGProps } from 'react';

const SvgCalendar = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M7 11H9V13H7V11Z" />
    <path d="M13 11H11V13H13V11Z" />
    <path d="M15 11H17V13H15V11Z" />
    <path d="M9 15H7V17H9V15Z" />
    <path d="M11 15H13V17H11V15Z" />
    <path d="M17 15H15V17H17V15Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 2V22H22V2H2ZM20 20H4V9H20V20ZM4 7H20V4H4V7Z"
    />
  </svg>
);
export default SvgCalendar;
