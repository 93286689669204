import type { SVGProps } from 'react';

const SvgCheckCheckbox = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.01428 9.85973L11.7739 2.73828L13.4367 3.84962L7.32081 13.0003L2.7594 8.42395L4.17593 7.01206L7.01428 9.85973Z"
    />
  </svg>
);
export default SvgCheckCheckbox;
