import type { SVGProps } from 'react';

const SvgUser = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 10C14.2091 10 16 8.20914 16 6C16 3.79086 14.2091 2 12 2C9.79086 2 8 3.79086 8 6C8 8.20914 9.79086 10 12 10ZM12 4C13.1046 4 14 4.89543 14 6C14 7.10457 13.1046 8 12 8C10.8954 8 10 7.10457 10 6C10 4.89543 10.8954 4 12 4Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 18C20 14.6863 17.3137 12 14 12H10C6.68629 12 4 14.6863 4 18V22H20V18ZM18 18V20H6V18C6 15.7909 7.79086 14 10 14H14C16.2091 14 18 15.7909 18 18Z"
    />
  </svg>
);
export default SvgUser;
