import type { SVGProps } from 'react';

const SvgZoomOut = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M14 11H6V9H14V11Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.312 14.897L21.998 20.584L20.584 21.998L14.897 16.312C13.543 17.365 11.846 18 10 18C5.589 18 2 14.411 2 10C2 5.589 5.589 2 10 2C14.411 2 18 5.589 18 10C18 11.846 17.365 13.543 16.312 14.897ZM4 10C4 13.309 6.691 16 10 16C13.309 16 16 13.309 16 10C16 6.691 13.309 4 10 4C6.691 4 4 6.691 4 10Z"
    />
  </svg>
);
export default SvgZoomOut;
