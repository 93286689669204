/* import { Agenda } from '@cue/types';
 */
import agendasJSON from '../../../local-data/json/agendas.json';
import { DirectusAgenda, IAgenda } from '@project/cms/modules';
import React from 'react';

export const useAgendaSettings = () => {
  const agendas = agendasJSON as unknown as DirectusAgenda[];

  function prepareAgenda(a: DirectusAgenda): IAgenda {
    return {
      ...a,
      categories: a.categories.map((c) => ({ ...c.categories_id })),
      location_categories: a.location_categories?.map((lc) => ({ ...lc.category_tags_id })),
      slot_pattern: a.slot_pattern?.map((sp) => ({ ...sp.slot_pattern_id })),
    };
  }

  const getAgendaById = (agendaId: string): IAgenda | undefined => {
    const agenda = agendas.map((a) => prepareAgenda(a)).find((a) => a.id === agendaId);

    if (!agenda) {
      console.error(`useAgendaSettings: No agendaSettings for agendaId "${agendaId}"`);
    }
    return agenda;
  };

  const getAgendaBySlug = React.useCallback(
    (agendaSlug: string): IAgenda | undefined => {
      const agenda = agendas.map((a) => prepareAgenda(a)).find((a) => a.slug === agendaSlug);

      if (!agenda) {
        console.error(`useAgendaSettings: No agendaSettings for agendaSlug "${agendaSlug}"`);
      }
      return agenda;
    },
    [agendas]
  );

  const getAllAgendas = (): IAgenda[] => {
    return agendas.map((a) => prepareAgenda(a));
  };

  function getCategoryColor(categoryId: string) {
    // Loop through the jsonData array to find the category with the matching id
    for (const item of agendas) {
      if (item?.location_categories) {
        for (const locationCategory of item?.location_categories) {
          const categoryTagsId = locationCategory.category_tags_id;
          if (categoryTagsId.category.id === categoryId) {
            // Return the color when the matching id is found
            return categoryTagsId.color;
          }
        }
      }
    }

    // If the category id is not found, return null or handle as appropriate
    return null;
  }

  return {
    getAgendaById,
    getAgendaBySlug,
    getAllAgendas,
    getCategoryColor,
  };
};
