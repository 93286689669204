import type { SVGProps } from 'react';

const SvgLanguage = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2ZM19.93 11H17C16.8521 8.65566 16.0634 6.39691 14.72 4.47C17.5455 5.49511 19.5579 8.01744 19.93 11ZM9 13C9.19012 15.4997 10.2519 17.8532 12 19.65C13.7481 17.8532 14.8099 15.4997 15 13H9ZM9 11C9.19012 8.50034 10.2519 6.14679 12 4.35C13.7481 6.14679 14.8099 8.50034 15 11H9ZM9.26714 4.48847L9.28 4.47H9.32L9.26714 4.48847ZM4 11C4.38877 8.00896 6.42632 5.49251 9.26714 4.48847C7.93152 6.41134 7.14742 8.66315 7 11H4ZM4.07 13H7C7.14789 15.3443 7.93655 17.6031 9.28 19.53C6.45453 18.5049 4.44208 15.9826 4.07 13ZM17 13C16.8403 15.3483 16.0377 17.6073 14.68 19.53C17.5484 18.5386 19.6088 16.0096 20 13H17Z"
    />
  </svg>
);
export default SvgLanguage;
