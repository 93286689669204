import type { SVGProps } from 'react';

const SvgYoutube = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.95449 15.0189V9.07188L15.1825 12.0449L9.95449 15.0189ZM21.5825 7.20088C21.3515 6.33388 20.6745 5.65188 19.8135 5.41988C18.2545 4.99988 12.0005 4.99988 12.0005 4.99988C12.0005 4.99988 5.74549 4.99988 4.18549 5.41988C3.32549 5.65188 2.64749 6.33388 2.41749 7.20088C2.00049 8.76988 2.00049 12.0449 2.00049 12.0449C2.00049 12.0449 2.00049 15.3209 2.41749 16.8909C2.64749 17.7559 3.32549 18.4389 4.18549 18.6699C5.74549 19.0909 12.0005 19.0909 12.0005 19.0909C12.0005 19.0909 18.2545 19.0909 19.8135 18.6699C20.6745 18.4389 21.3515 17.7559 21.5825 16.8909C22.0005 15.3209 22.0005 12.0449 22.0005 12.0449C22.0005 12.0449 22.0005 8.76988 21.5825 7.20088Z"
    />
  </svg>
);
export default SvgYoutube;
